var __jsx = React.createElement;
/**
 * Represents Services Blade Component.
 * @author kkum
 */import React from 'react';
import styled from 'styled-components';
import styles from './ServicesBlade.style';
import TextCard from '../../molecules/TextCard';
import Heading from '../../atoms/Heading';
import RichText from '../../atoms/RichText/RichText';
import Image from '../../atoms/Image';
import constants, { siteConstants } from '../../../constants/globalConstants';
import analyticsConstants from '../../../constants/analyticsConstants';
import { servicesBladeLabels } from '../../../constants/labels/en';
import Anchor from '../../atoms/Anchor';
import { createLinkID, stripHashfromStart } from '../../../utils/utilityFunction';
// import slugify from 'url-slug';
import urlSlug from 'url-slug';
import { imageRenditions } from './config';
var ServicesBlade = function ServicesBlade(_ref) {
  var _servicesBladeData$sy;
  var servicesBladeData = _ref.servicesBladeData,
    pageUrl = _ref.pageUrl,
    className = _ref.className;
  if (!servicesBladeData) {
    return null;
  }
  var serviceTileCollection = servicesBladeData.serviceTileCollection,
    sectionHeadline = servicesBladeData.sectionHeadline,
    anchorLink = servicesBladeData.anchorLink,
    bodyText = servicesBladeData.bodyText,
    hideBlade = servicesBladeData.hideBlade;
  var jumpLinkId = createLinkID(stripHashfromStart(anchorLink)) || createLinkID(sectionHeadline);
  return __jsx("div", {
    className: "nva-service-blade ".concat(className),
    id: servicesBladeData === null || servicesBladeData === void 0 ? void 0 : (_servicesBladeData$sy = servicesBladeData.sys) === null || _servicesBladeData$sy === void 0 ? void 0 : _servicesBladeData$sy.id,
    style: {
      display: hideBlade ? 'none' : null
    }
  }, __jsx("div", {
    className: "services-blade container"
  }, __jsx("div", {
    className: "service-container"
  }, sectionHeadline && __jsx(Heading, {
    HeadingType: "h2",
    className: "nva-service-blade__heading blade-heading",
    id: jumpLinkId
  }, sectionHeadline), __jsx(RichText, {
    jsonContent: bodyText && bodyText.json,
    className: "nva-service-blade__description blade-description"
  }), __jsx("div", {
    className: "service-tile-container"
  }, serviceTileCollection && serviceTileCollection.items && serviceTileCollection.items.map(function (tileInitial, index) {
    var tile = tileInitial.serviceDetails;
    var serviceURL = tile && tile.serviceUrl && ((tile === null || tile === void 0 ? void 0 : tile.serviceUrl[0]) == '/' || (tile === null || tile === void 0 ? void 0 : tile.serviceUrl[0]) == '#') ? tile.serviceUrl.substring(1) : tile.serviceUrl;
    var divider = serviceURL && (tile === null || tile === void 0 ? void 0 : tile.serviceUrl[0]) == '#' ? '#' : '/';
    var cta = {
      label: servicesBladeLabels.learnMore,
      url: "".concat(pageUrl).concat(divider).concat(serviceURL) || "/".concat(constants.pageName.services, "/").concat(urlSlug(tile.serviceName))
    };
    var imageSrc = tile.galleryCollection && tile.galleryCollection.items && tile.galleryCollection.items.length && tile.galleryCollection.items[0].image && tile.galleryCollection.items[0].image.url;
    var altText = tile.galleryCollection && tile.galleryCollection.items && tile.galleryCollection.items.length && tile.galleryCollection.items[0].altText;
    var imgTitle = tile.galleryCollection && tile.galleryCollection.items && tile.galleryCollection.items.length && (tile.galleryCollection.items[0].title || tile.galleryCollection.items[0].altText);
    if (!tileInitial.hideService) {
      return __jsx(Anchor, {
        key: tileInitial.sys && tileInitial.sys.id,
        className: "nva-service-blade__tile service-tile",
        id: tileInitial.sys && tileInitial.sys.id,
        title: "Click Here to Learn More about ".concat(tile.serviceName),
        to: cta.url,
        ctaBehavior: cta.behavior,
        hyperlinkType: cta.hyperlinkType,
        "aria-label": tile.serviceName,
        "data-analytics-type": analyticsConstants.servicesBlade.analyticsType.CTA_SERVICES_TITLE,
        "data-analytics-value": tile.serviceName,
        "data-analytics-variation": ""
      }, __jsx("div", {
        className: "nva-service-blade__image image-wrapper"
      }, __jsx(Image, {
        src: imageSrc,
        alt: altText,
        title: imgTitle,
        srcSetSettingImg: {
          imageRenditions: imageRenditions
        }
      })), __jsx(TextCard, {
        className: "nva-service-blade__text service-text",
        headingText: tile.serviceName,
        headingType: "h3",
        descriptionType: siteConstants.RICH_TEXT,
        descriptionText: tile.shortDescription && tile.shortDescription.json || tile.serviceOverview && tile.serviceOverview.json,
        cta: cta,
        isAlreadyWraped: true,
        "data-analytics-type": analyticsConstants.servicesBlade.analyticsType.CTA_SERVICES_TITLE,
        "data-analytics-value": tile.serviceName,
        "data-analytics-variation": ""
      }));
    }
  })))));
};
ServicesBlade.defaultProps = {
  className: ''
};
export default styled(ServicesBlade).withConfig({
  componentId: "sc-5trg3i-0"
})(["", ";"], styles);